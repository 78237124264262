import $api from '../services/api'

const state = {
  busy: false,
  error: null,
  token_id: null,
  data: {},
  company_result: null
}

const getters = {}

const actions = {

  async get ({ commit }, token_id) {
    if (!token_id) {
      commit('SET', {})
      return
    }
    commit('BUSY')
    try {
      commit('SET', await $api.links.get(token_id))
    } catch (error) {
      console.log(error)
      commit('BAD', error.message)
    }
  },
  async proceedas({commit}, data) {
    commit('BUSY')
    try {
      commit('SET', await $api.links.proceedas(state.token_id, data))
    } catch (error) {
      commit('BAD', error.message)
    }
  },
  async newProceedas({commit}, data) {
    commit('BUSY')
    try {
      commit('SET', await $api.links.newProceedas(state.token_id, data))
    } catch (error) {
      commit('BAD', error.message)
    }
  },
  async addDoc({commit}, formData) {
    commit('BUSY')
    try {
      commit('SET', await $api.links.addDoc(formData))
    } catch (error) {
      commit('BAD', error.message)
    }
  },
  async userConfirmCdd({commit}, formData) {
    commit('BUSY')
    try {
      commit('SET', await $api.links.userConfirmCdd(formData))
    } catch (error) {
      commit('BAD', error.message)
    }
  },
  async addDocAndRecheck({commit}, formData) {
    commit('BUSY')
    try {
      commit('SET', await $api.links.addDocAndRecheck(formData))
    } catch (error) {
      commit('BAD', error.message)
    }
  },
  async validatePhone({commit}, attr) {
    commit('BUSY')
    try {
      commit('SET', await $api.links.validatePhone(state.token_id, attr))
    } catch (error) {
      commit('BAD', error)
    }
  },
  async searchCompany({commit}, attr) {
    commit('BUSY')
    try {
      commit('SET_COMPANY_INFO', await $api.links.searchCompany(attr))
    } catch (error) {
      commit('BAD', error)
    }
  },
  async manualCompany({commit}, attr) {
    commit('BUSY')
    try {
      commit('SET_COMPANY_INFO', attr)
    } catch (error) {
      commit('BAD', error)
    }
  },
  async addCompany({commit}, attr) {
    commit('BUSY')
    try {
      commit('SET', await $api.links.addCompany(state.token_id, attr))
    } catch (error) {
      commit('BAD', error)
    }
  },
  async resetCompanySearch({commit}) {
    try {
      commit('RESET_COMPANY_SEARCH', {})
    } catch (error) {
      commit('BAD', error)
    }
  },
  async uploadLEDocs({commit}, formData) {
    commit('BUSY')
    try {
      commit('SET', await $api.links.uploadLEDocs(formData))
    } catch (error) {
      commit('BAD', error)
    }
  },
  async addNewDir({commit}, attr) {
    commit('BUSY')
    try {
      commit('SET', await $api.links.addNewDir(state.token_id, attr))
    } catch (error) {
      commit('BAD', error)
    }
  },
  async addNewUbo({commit}, attr) {
    commit('BUSY')
    try {
      commit('SET', await $api.links.addNewUbo(state.token_id, attr))
    } catch (error) {
      commit('BAD', error)
    }
  },
  async addCompanyResource({commit}, attr) {
    commit('BUSY')
    try {
      commit('SET', await $api.links.addCompanyResource(state.token_id, attr))
    } catch (error) {
      commit('BAD', error)
    }
  },
  async getAppStatus({commit}) {
    commit('BUSY')
    try {
      return await $api.links.getAppStatus(state.token_id)
    } catch (error) {
      throw error
    }
  },
  async createReport({commit}) {
    commit('BUSY')
    try {
      commit('SET', await $api.links.createReport(state.token_id))
    } catch (error) {
      throw error
    }
  },
  async createBusinessReport({commit}) {
    commit('BUSY')
    try {
      commit('SET', await $api.links.createBusinessReport(state.token_id))
    } catch (error) {
      throw error
    }
  },
  async uploadProfilePic({commit}, formData) {
    commit('BUSY')
    try {
      commit('SET', await $api.links.uploadProfilePic(formData))
    } catch (error) {
      commit('BAD', error)
    }
  },
  async confirmInfo({commit}) {
    commit('BUSY')
    try {
      commit('SET', await $api.links.confirmInfo(state.token_id))
    } catch (error) {
      commit('BAD', error.message)
    }
  },
  async createUserAuto({commit}) {
    // commit('BUSY')
    try {
      return await $api.links.createUserAuto(state.token_id)
    } catch (error) {
      commit('BAD', error.message)
    }
  },
  async confirmCre({commit}, want_card) {
    commit('BUSY')
    try {
      commit('SET', await $api.links.confirmCre(state.token_id, want_card))
    } catch (error) {
      commit('BAD', error.message)
    }
  },
  async getLatestStatus({commit}, app_id) {
    commit('BUSY')
    try {
      commit('SET', await $api.links.getLatestStatus(app_id))
    } catch (error) {
      commit('BAD', error.message)
    }
  },
  async moveToCheck({commit}, app_id) {
    try {
      return await $api.links.moveToCheck(app_id)
    } catch (error) {
      throw error
    }
  },
  async updateDirAndLE({commit}, attr) {
    try {
      commit('ISUBO', attr)
    } catch (error) {
      throw error
    }
  },
  async removeDir({commit}, attr) {
    try {
      commit('REMOVEDIR', attr)
    } catch (error) {
      throw error
    }
  },
  async removeMainDirFromUbo({commit}) {
    try {
      commit('REMOVEMAINDIRFROMUBO')
    } catch (error) {
      throw error
    }
  },
  async addNewDirLE({commit}, attr) {
    try {
      commit('ADD_DIR_LE', attr)
    } catch (error) {
      throw error
    }
  },
  async addNewUboLE({commit}, attr) {
    try {
      commit('ADD_UBO_LE', attr)
    } catch (error) {
      throw error
    }
  },
  async addNewDirAtSum({commit}, attr) {
    try {
      commit('ADD_DIR_SUM', attr)
    } catch (error) {
      throw error
    }
  },
  async addNewUboAtSum({commit}, attr) {
    try {
      commit('ADD_UBO_SUM', attr)
    } catch (error) {
      throw error
    }
  },
  async editUpdateIsUbo({commit}, attr) {
    try {
      commit('updateEditIsUbo', attr)
    } catch (error) {
      throw error
    }
  },
  async sendEmail({commit}, attr) {
    try {
      return await $api.links.sendEmail(state.token_id, attr)
    } catch (error) {
      throw error
    }
  }
}

const mutations = {
  updateEditIsUbo (state, attr) {
    const objIdx = state.data.user_info.company[attr.arr].findIndex(item => item.id === attr.person.id)

    if (attr.arr === 'ubo') {
      state.data.user_info.company[attr.arr][objIdx].appId = attr.person.appId
      state.data.user_info.company[attr.arr][objIdx].country = attr.person.country
      state.data.user_info.company[attr.arr][objIdx].dob = attr.person.dob
      state.data.user_info.company[attr.arr][objIdx].fname = attr.person.fname
      state.data.user_info.company[attr.arr][objIdx].id = attr.person.id
      state.data.user_info.company[attr.arr][objIdx].lname = attr.person.lname
    }

    if (attr.arr === 'officer') {
      state.data.user_info.company[attr.arr][objIdx].appId = attr.person.appId
      state.data.user_info.company[attr.arr][objIdx].country = attr.person.country
      state.data.user_info.company[attr.arr][objIdx].dob = attr.person.dob
      state.data.user_info.company[attr.arr][objIdx].fname = attr.person.fname
      state.data.user_info.company[attr.arr][objIdx].id = attr.person.id
      state.data.user_info.company[attr.arr][objIdx].isUbo = attr.person.isUbo
      state.data.user_info.company[attr.arr][objIdx].lname = attr.person.lname
    }
    if (attr.arr === 'dir_LE') {
      state.data.user_info.company[attr.arr][objIdx].cddFile = attr.person.cddFile
      state.data.user_info.company[attr.arr][objIdx].company = attr.person.company
      state.data.user_info.company[attr.arr][objIdx].id = attr.person.id
      state.data.user_info.company[attr.arr][objIdx].isUbo = attr.person.isUbo
      state.data.user_info.company[attr.arr][objIdx].uname = attr.person.uname
    }
    if (attr.arr === 'ubo_LE') {
      state.data.user_info.company[attr.arr][objIdx].cddFile = attr.person.cddFile
      state.data.user_info.company[attr.arr][objIdx].company = attr.person.company
      state.data.user_info.company[attr.arr][objIdx].id = attr.person.id
      state.data.user_info.company[attr.arr][objIdx].uname = attr.person.uname
    }

  },
  REMOVEMAINDIRFROMUBO (state) {
    state.data.user_info.company.defaultDirIsUBO = false
  },
  REMOVEDIR (state, attr) {
    const objIdx = state.data.user_info.company[attr.arr].findIndex((obj => obj.id == attr.id))
    state.data.user_info.company[attr.arr].splice(objIdx, 1)
  },
  ADD_DIR_SUM (state, attr) {
    state.data.user_info.company.officer.push(attr)
  },
  ADD_DIR_LE (state, attr) {
    state.data.user_info.company.dir_LE.push(attr)
  },
  ADD_UBO_LE (state, attr) {
    state.data.user_info.company.ubo_LE.push(attr)
  },
  ADD_UBO_SUM (state, attr) {
    state.data.user_info.company.ubo.push(attr)
  },
  RESET_COMPANY_SEARCH (state) {
    state.company_result = null
  },
  ISUBO (state, attr) {
    const objIdx = state.data.user_info.company[attr.arr].findIndex((obj => obj.id == attr.id))
    state.data.user_info.company[attr.arr][objIdx].isUbo = false
  },
  BUSY (state) {
    state.error = null
    state.busy = true
  },
  GET (state, token_id) {
    state.error = null
    if (!token_id) {
      state.token_id = null
      state.data = {}
      return
    }
    state.busy = true
  },
  SET (state, data) {
    state.busy = false
    state.error = null
    state.token_id = data.token_id
    state.data = data
  },
  SET_COMPANY_INFO (state, data) {
    state.busy = false
    state.error = null
    state.company_result = data
  },
  BAD (state, message) {
    state.busy = false
    state.error = message
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
